import lottie from 'lottie-web';

class LottiePlayer extends HTMLElement {
  constructor() {
    super();
    this.shadowEl = this.attachShadow({ mode: 'closed' });
  }

  get src() {
    return this.getAttribute('src') || '';
  }

  async animate() {
    if (!this.src) return;
    let jsonData = await fetch(this.src).then((res) => res.json());
    if (typeof jsonData === 'string') {
      jsonData = JSON.parse(jsonData);
    }
    this.lottie = lottie.loadAnimation({
      container: this.el,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: jsonData,
      rendererSettings: {
        scaleMode: 'centerCrop',
        clearCanvas: true,
        progressiveLoad: false,
        hideOnTransparent: true,
      },
    });
    this.lottie.setSpeed(1);
    this.lottie.play();
  }

  render() {
    this.el = document.createElement('div');
    this.styleEl = document.createElement('style');
    this.styleEl.innerText = /* css */ `
    :host {
      display: block;
      width: 100%;
      height: 100%;
    }
    `;
    this.shadowEl.appendChild(this.el);
    this.shadowEl.appendChild(this.styleEl);
    this.animate();
  }

  connectedCallback() {
    this.render();
  }

  disconnectedCallback() {
    this.mounted = false;
    if (this.lottie) this.lottie.destroy();
  }
}
if (!window.customElements.get('lottie-player')) {
  window.customElements.define('lottie-player', LottiePlayer);
}
